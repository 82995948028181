@import "assets/scss/variables";

.loginFailWrap {
  padding: clamp(36px, 3vw, 50px);
  border-radius: 8px;
  background-color: rgba($colorDanger, 0.05);
  border: 2px solid $colorDanger;

  h2 {
    color: $white;
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 900;
  }

  a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
}