//== Import Sass Files
@import "~bootstrap/scss/bootstrap";
@import "assets/scss/variables";
@import "assets/scss/global";
@import "assets/scss/buttons";
@import "assets/scss/formInput";
@import "assets/scss/infiniteScroll";
@import "assets/scss/loader";
@import "assets/scss/customRangeSlider";
@import "assets/scss/offCanvasDrawer";

//========= Fonts Starts
@font-face {
  font-family: 'Adobe Clean', sans-serif;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Adobe Clean'), url('./assets/fonts/AdobeClean-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Adobe Clean', sans-serif;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Adobe Clean'), url('./assets/fonts/AdobeClean-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Adobe Clean', sans-serif;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local('Adobe Clean'), url('./assets/fonts/AdobeClean-ExtraBold.woff2') format('woff2');
}

//========== Fonts Ends

body {
  font-family: 'Adobe Clean', sans-serif !important;
  font-weight: 300;
  background-color: #121212 !important;
  color: white !important;
  overflow-wrap: break-word;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1f1f1f inset !important;
  -webkit-text-fill-color: $greyTextColor !important;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

#root {
  isolation: isolate;
}

.word-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 768px) {
  .tooltip {
    display: none !important;
    z-index: -1 !important;
  }
}

