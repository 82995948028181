@import "variables";

.cardList {
  padding: 0;
  width: 100%;
  overflow: hidden;
  position: relative;

  .cardItem {
    display: flex;
    position: absolute !important;
    width: calc((1 / 4 * 100%) - 15px);
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 4px;

    .cardItemWrap {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 992px) {
      width: calc((1 / 3 * 100%) - 14px);
    }

    @media (max-width: 768px) {
      width: calc((1 / 2 * 100%) - 10px);
    }

    @media (max-width: 576px) {
      width: calc((1 / 2 * 100%) - 3px);
      margin-bottom: 6px;
    }
  }
}

@for $i from 1 through length($colorsLoop) {
  li.cardItem:nth-child(#{length($colorsLoop)}n+#{$i}) {
    > div {
      background-color: nth($colorsLoop, $i);
    }
  }
}

.userGifCards {
  background-color: transparent;
  .cardList {
    .cardItem {
      width: calc((1 / 3 * 100%) - 14px);
      @media (max-width: 768px) {
        width: calc((1 / 2 * 100%) - 10px);
      }
      @media (max-width: 768px) {
        width: calc((1 / 2 * 100%) - 3px);
      }
    }
  }

  h2 {
    font-weight: 300;
    border-bottom: none;
  }

  h3 {
    font-size: clamp(22px, 3vw, 24px);
    margin: 15px 0;
  }

  .sticker-card,
  .sticker-card-place {
    height: 125px;
  }
}

.userGifCards {
  .dashboardCardLoader {
    columns: 3;
    @media (max-width: 768px) {
      columns: 2;
    }
  }
}
