@import "src/assets/scss/variables";

.FallbackErrorWrap {
  min-height: calc(100dvh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .lottieWrap {
    margin-inline: auto;
    max-width: 450px;
  }

  p, button {
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  p {
    color: rgba($white, 0.95);
    font-size: clamp(1.25rem, 1.1019rem + 0.7407vw, 1.75rem);
    font-weight: 700;
  }

  button {
    color: $colorPrimary;
    font-size: clamp(0.9375rem, 0.8819rem + 0.2778vw, 1.125rem);
    transition: color .3s;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    &:hover {
      color: $borderOpacityColor;
    }
  }

  @media (max-width: 576px) {
    min-height: calc(100dvh - 350px);
    .lottieWrap {
      max-width: 300px;
    }
  }
}