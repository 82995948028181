@import "variables";

.customSliderRange {
  -webkit-appearance: none;
  height: 10px;
  background-color: rgba($borderColor, 0.30);
  border-radius: 5px;
  background-image: linear-gradient($colorPrimary, $colorPrimary);
  background-repeat: no-repeat;
  width: 100%;
}

.customSliderRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: $white;
  border: 5px solid $colorPrimary;
  cursor: pointer;
  transition: background 0.4s ease-in-out;
}

.customSliderRange::-moz-range-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #fff;
  border: 5px solid $colorPrimary;
  cursor: pointer;
  transition: background 0.4s ease-in-out;
}

.customSliderRange::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.customSliderRange::-webkit-slider-thumb:hover {
  box-shadow: rgba($colorPrimary, 0.50) 0px 0px 0px 8px;
}

.customSliderRange::-moz-range-thumb:hover {
  box-shadow: rgba($colorPrimary, 0.50) 0px 0px 0px 8px;
}

.customSliderRange::-webkit-slider-thumb:active {
  box-shadow: rgba($colorPrimary, 0.50) 0px 0px 0px 11px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.customSliderRange::-moz-range-thumb:active {
  box-shadow: rgba($colorPrimary, 0.50) 0px 0px 0px 11px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}