.tabLoaderWrap {
  min-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  animation: spinReverse 4500ms linear infinite;
}

svg {
  max-width: 100%;
  height: auto;
}

@keyframes spinReverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}