@import "variables";

.modal.show {
  backdrop-filter: blur(4px);
}

@media (min-width: 992px) {
  .modal-lg {
    --bs-modal-width: 925px !important;
  }
}

@media (max-width: 400px) {
  .fade.modal.show {
    padding-left: 0 !important;
  }
}

.tooltip-inner {
  color: $linkColor;
  background-color: $inputBgColor;
}

.tooltip-arrow:before {
  border-top-color: $inputBgColor !important;
}

.nav-item a.active {
  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    background: linear-gradient(89deg, #1D84FA, #9628F7);
    height: 3px;
    width: 100%;
    border-radius: 2px;
  }
}

//== Typography
h1, h2, h3, h4, h5 {
  font-family: 'Adobe Clean', sans-serif;
  font-weight: 700;
}

h6 {
  font-weight: 300;

  strong {
    font-weight: 700;
  }
}

h2 {
  margin-bottom: clamp(24px, 3vw, 28px);
  font-size: clamp(24px, 3vw, 28px);
}

//== Alert Box
.success {
  background-color: $colorPrimary !important;
  max-height: 75px !important;
}

.warning {
  background-color: rgb(255, 171, 0) !important;
  max-height: 75px !important;
}

.error {
  background-color: $colorDanger !important;
  max-height: 75px !important;
}

.default {
  background-color: $colorPrimary !important;
  max-height: 75px !important;
}

.error-input {
  border-color: #dc3545 !important;
}

.error-input-message {
  color: #dc3545 !important;
}

//== Static Pages
.title {
  color: $white;
  font-size: clamp(20px, 4vw, 35px);
  font-weight: 900;
  line-height: 1.4;
  margin: 30px 0 20px;
  padding-bottom: 12px;
  border-bottom: 3px solid $inputBgHoverColor;

  @media (max-width: 576px) {
    margin: 16px 0;
    padding-bottom: 5px;
  }
}

.subtitle {
  color: $white;
  font-size: clamp(15px, 4vw, 16px);
  font-weight: 700;
  line-height: 40px;
  margin: 10px 0;
}

.text {
  color: rgba($white, 0.70);
  font-size: clamp(15px, 3vw, 16px);
  line-height: 1.7;
  font-weight: 300;
  margin-top: 8px;
}

.term-policy {
  p {
    margin-left: -25px;
  }
}

.privacy-policy {
  .heading {
    font-weight: 700;
  }
}


//== Faq Page
.faq {
  .accordion {
    --bs-accordion-btn-icon: url("../icons/plus.svg");
    --bs-accordion-btn-active-icon: url("../icons/minus.svg");
    --bs-accordion-bg: #171717;
    --bs-accordion-color: $white;
    --bs-accordion-btn-color: $white;
    --bs-accordion-border-color: $borderColor;
    --bs-accordion-btn-focus-border-color: $white;
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-active-color: $white;
    --bs-accordion-active-bg: #171717;
    --bs-accordion-btn-icon-transition: transform 0.5s ease-in-out;
    --bs-accordion-btn-icon-transform: rotate(-180deg) translateY(10px);

    ul {
      padding-left: 82px;

      @media (max-width: 1200px) {
        padding-left: 70px;
      }

      @media (max-width: 992px) {
        padding-left: 65px;
      }

      @media (max-width: 768px) {
        padding-left: 18px;
      }
    }
  }

  .accordion-button {
    flex-direction: row-reverse;
    justify-content: flex-start;
    width: auto;
    gap: clamp(20px, 3vw, 50px);
  }

  .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #171717;
  }
}

.faqWrapItems {
  ul li {
    line-height: 1.75;
    color: rgba($white, 0.80);
  }
}



