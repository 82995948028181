//== Fonts
$fontFamily: 'Adobe Clean', sans-serif;

//== Colors
$white: #fff;
$black: #000;
$bodyBgColor: #121212;
$sectionBgColor: #141414;
$inputBgHoverColor: #171717;
$inputBgColor: #1F1F1F;
$borderColor: #1BCC96;
$borderOpacityColor: rgba(#00FF99, 0.30);
$colorPrimary: #1BCC96;
$colorDanger: #FC4A4A;
$colorDangerOpacity: rgba(#FC4A4A, 0.15);
$favHover: #F15A5A;
$greyTextColor: #A2A2A2;
$linkColor: #1EE3A7;
$colorsLoop: #A70DFF, #FFF35C, #1EE6A9, #F15A5A, #2EBDFF, #FFF35C, #F15A5A, #A70DFF;
$inactiveColor: #D9D9D9;
$statHeroBg: #792FFE;
$breadcrumbLinks: #FFC023;
$hrLineColor: #1a1a1a