@import "variables";

input[type='text'],
input[type='email'],
input[type='password'],
select,
textarea,
.form-control {
  background-color: $inputBgColor;
  padding: 12px 24px;
  border-color: transparent;
  outline: transparent;
  vertical-align: middle;
  font-size: clamp(15px, 3vw, 17px);
  color: $white;
  border-radius: 5px;
  margin-bottom: 17px;

  &:focus, &:hover {
    background-color: rgba($borderColor, 0.05);
    box-shadow: none;
    border-color: $borderColor;
    outline-color: $borderColor;
    color: $greyTextColor;
  }

  &:disabled {
    background-color: $inputBgColor;
    opacity: 0.9;
    cursor: auto;
  }
}

.form-check-input {
  background-color: $inputBgColor;
  &:checked {
    border-color: $borderColor;
  }
}

input[type='checkbox'],
input[type='checkbox'] {
  border: 1.5px solid $borderColor;
  border-radius: 4px;
  &:focus, &:hover {
    box-shadow: 0 0 0 0.15rem rgba($borderColor, 0.25);
  }

  &:checked {
    background-color: $borderColor;
    color: $greyTextColor;
  }
}

label {
  color: $greyTextColor;
  font-size: 15px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #1F1F1F inset !important;
  -webkit-text-fill-color: #1F1F1F!important;
  -webkit-background-clip: text !important;
}