@import "variables";

.g-btn {
  border-radius: 35px;
  border: 1px solid $borderColor;
  padding: 10px 22px;
  background-color: rgba($borderColor, 0.15);
  color: $white;
  font-size: clamp(16px, 3vw, 18px);
  font-weight: 700;
  transition: ease-in-out all 300ms;
  min-width: 120px;

  &:hover,
  &.active,
  &.show,
  &:first-child:active {
    background-color: $colorPrimary;
    border-color: $colorPrimary;
  }

  &:disabled {
    background-color: $colorPrimary;
    border-color: $colorPrimary;
    opacity: 0.85;
  }

  @media all and (max-width: 992px) {
    padding: 7px 16px;
    min-width: 100px;
  }

  @media (max-width: 768px) {
    border-radius: 3px;
    padding: 8px 18px;
    background-color: $colorPrimary;
  }
}

.g-btn-danger {
  border-color: $colorDanger;
  background-color: $colorDangerOpacity;

  &:hover,
  &.active,
  &.show,
  &:first-child:active {
    background-color: $colorDanger;
    border-color: $colorDanger;
  }

  &:disabled {
    background-color: $colorDanger;
    border-color: $colorDanger;
    opacity: 0.85;
  }

  @media (max-width: 768px) {
    background-color: $colorDanger;
  }
}