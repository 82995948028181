@import "variables";
.offcanvas {
  background-color: $bodyBgColor;
  hr {
    border-color: #262626 !important;
  }

  @media (max-width: 992px) {
    max-width: 350px;
  }

  @media (max-width: 576px) {
    max-width: 290px;
  }
}

.offcanvas-backdrop {
  backdrop-filter: blur(1px);
  background-color: rgba(#000, 0.50);
  &.show {
    opacity: 1;
  }
}